import React from 'react';
import vkIcon from './png/vk-icon.png';
import tgIcon from './png/tg-icon.png';

function SocialLinks() {
  return (
    <div className="social-links">
      <div className="social-links-vk">
        <a href="https://vk.com/cathev" target="_blank" rel="noopener noreferrer">
          <img src={vkIcon} alt="VK" />
        </a>
      </div>
      <div className='social-links-tg'>
        <a href="https://t.me/l_CatheV_l" target="_blank" rel="noopener noreferrer">
          <img src={tgIcon} alt="Telegram" />
        </a>
      </div>
    </div>
  );
}

export default SocialLinks;