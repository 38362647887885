import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './components/Logo';
import SocialLinks from './components/SocialLinks';
import './App.css';

function App() {
  return (
    <div className="app">
        <div className="app-content">
            <div className="text">Скоро здесь будет сайт</div>
            <Logo />
            <SocialLinks />
        </div>
      <footer className="Login-app-footer">
        <span className="Login-copyright-text">© CatheV</span>
        <div className="Login-footer-links">
          <Link to="/about">Информация о проекте</Link>
          <a href="https://kraskrit.ru/" target="_blank" rel="noopener noreferrer">Сайт учебного заведения</a>
          <a href="https://t.me/EugeneKugene" target="_blank" rel="noopener noreferrer">Официальный Телеграм бот (в разработке)</a>
          <a href="https://t.me/gennadiius" target="_blank" rel="noopener noreferrer">Тех поддержка (Администрация в Телеграме)</a>
        </div>
      </footer>
    </div>
  );
}

export default App;